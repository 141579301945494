@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Marmelad", 'Fira Sans', 'Droid Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


h1,
h2,
h3,
p {
  margin: 0;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}


.loader {
  position: relative;
  width: 100px;
  height: 16px;
}

.loader:before,
.loader:after {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #EDEDED;
  box-shadow: 32px 0 #EDEDED;
  left: 0;
  top: 0;
  animation: ballMoveX 1s linear infinite;
}

.loader:after {
  box-shadow: none;
  transform-origin: 40px 0;
  transform: rotate(-153deg);
  animation: rotateLoader 1s linear infinite;
}

@keyframes rotateLoader {

  0%,
  10% {
    transform: rotate(-153deg);
  }

  90%,
  100% {
    transform: rotate(0deg);
  }
}

@keyframes ballMoveX {

  0%,
  10% {
    transform: translateX(0)
  }

  90%,
  100% {
    transform: translateX(32px)
  }
}

.custom-hover {
  transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1), box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1);
}

.custom-hover:hover {
  box-shadow: 10px 10px 25px -8px rgb(255, 255, 255);
  scale: 1.01;
}

.custom-ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

.button-blue {
  cursor: pointer;
  font-family: inherit;
  color: white;
  background: #1E6D9A;
  padding: 10px 28px;
  border: 1px solid #1E6D9A;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  font-style: inherit;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
}

.button-blue:hover {
  transform: scale(104%);
}