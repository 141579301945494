/* Position and sizing of burger button */
.bm-burger-button {
    position: absolute;
    width: 30px;
    height: 25px;
    right: 10px;
    top: -12px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
    background: #1E6D9A;
    border-radius: 5px;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
    background: #000000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
    height: 40px;
    width: 40px;
}

/* Color/shape of close button cross */
.bm-cross {
    background: #1E6D9A;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
}

/* General sidebar styles */
.bm-menu {
    background: #EDEDED;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
    display: flex;
}


/* Wrapper for item list */
.bm-item-list {
    display: flex;
    flex-direction: column;
    color: #b8b7ad;
    padding: 0.8em;
    gap: 10px;
}

/* Individual item */
.bm-item {
    display: flex;
    flex-direction: column;
    padding: 20px;
}

/* Styling of overlay */
.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
    width: 600pxnpm;
}

.menu-item {
    font-family: inherit;
    font-size: x-large;
}